:root {
  --primary: #b5dcdcca;
  --logo-blue: #74b9b9;
  --secondary: #ce6d66;
  --light-orange: #f9bc9966;
  --light-blue: #ebffff90;
  --light: rgb(249, 249, 249);
  --light-bg: rgba(238, 238, 238, 0.655);
  --light-alt: #c7c7c7c6;
  --grey-icons: rgb(122, 122, 122);
  --table-th-bg: rgb(238, 238, 238);
  text-align: center;
  --dark: #232f44;
  --dark-alt: #2f3134;
  --orange-logo: #ce6d66;
  --sidebar-blue: #075cb7;
  --speed: 500ms;
  --border-radius: 5px;
  /* new-branding-colors */
  --yellow-logo: #F7EC34;
  --orange-logo: #fdbd2e;
  --yellow-soft: #f9e8b2;
  --black-logo: #2a2a2a;
  --dark-alt: #4f4f4f;
  --strong-green: #219F8E;
  --strong-blue: #304DFC;
  --soft-blue: #336BB0;
  --orange: #FC7930;
  --light-white: #f1f3f6;
  --light-grey: #f3f3f3;
  --background-grey: #F9F9F9;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

/* General */
body {
  text-align: center;
  line-height: 1.3;
  color: var(--dark-alt);
  font-size: 15px;
}

.App {
  padding: 22px;
}

p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--dark-alt);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  color: var(--dark-alt);
}

h1 {
  font-size: 30px;
  margin-bottom: 22px;
  font-weight: 700;
}

h2 {
  font-size: 26px;
  font-weight: 600;
}

h3 {
  font-size: 22px;
  font-weight: 500;
}

h4 {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--strong-green);
  font-family: "Fira Sans", sans-serif;
}

h5 {
  font-size: 1.1rem;
  font-weight: 500;
}

h6 {
  font-size: 1rem;
  font-weight: 500;
}

a {
  color: var(--dark-alt);
  text-decoration: none;
}

button,
select {
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.95rem;
  margin: 5px;
}

button {
  background-color: var(--strong-green);
  color: var(--light);
}

select {
  background-color: var(--yellow-logo);
  color: var(--dark-alt);
  width: 100%;
}

.greyButton {
  background-color: var(--light-grey);
  color: var(--dark-alt);
}

.uploadFileBtn,
.downloadTemplateBtn {
  background-color: var(--strong-green);
  color: var(--light);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
}

.uploadFileBtn {
  padding: 9px;
}

.downloadTemplateBtn a {
  color: var(--light);
  text-decoration: none;
  padding: 0px !important;
  margin: 0% !important;
}

/* font-awesome */

.fas {
  margin-left: 10px;
}

.fa-trash,
.fa-pen {
  color: var(--dark-alt);
  cursor: pointer;
  margin: 0px 5px;
}

.fa-trash:hover,
.fa-pen:hover {
  color: var(--strong-green) !important;
}

.fa-arrow-right,
.fa-arrow-left {
  margin: 0px 5px;
}

.fa-question-circle {
  color: #5d5d5f9b;
  font-size: 1.1rem;
  margin-left: 10px;
}

/* (instead of using a href) */
.transparentBtn {
  background-color: transparent;
  color: var(--dark-alt);
  border: none;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  text-decoration: underline;
}

.yellowButton {
  background-color: var(--yellow-logo);
  color: var(--dark-alt);
}

button:hover,
select:hover,
.greyButton:hover,
.uploadFileBtn:hover {
  background-color: var(--dark-alt);
  color: var(--light);
  border: none;
}

.transparentBtn:hover {
  color: var(--strong-green);
  background-color: transparent;
  text-decoration: underline;
}


button:disabled,
button[disabled],
select:disabled,
input:disabled,
.greyButton:disabled,
.disabled,
.uploadFileBtn:disabled {
  background-color: #c5c5c5;
  color: #666666;
  cursor: auto;
  border: none;
}

.successMessage {
  color: var(--strong-green);
  font-size: 0.95rem;
  margin: 10px 0px;
  height: 20px;
}

.errorMessage {
  color: var(--orange);
  font-size: 0.95rem;
}

/* search inputs */
.search-input-wrapper {
  position: relative;
  /* display: inline-block; */
  /* margin-bottom: 20px; */
}

.search-icon {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  color: gray;
  font-size: 0.9rem;
  pointer-events: none;
}

.search-input {
  border: none;
  text-align: left;
  font-size: 1rem;
  padding: 10px 10px 10px 40px;
  border-radius: 4px;
  background-color: var(--background-grey);
  box-shadow: inset 0 0 0.1rem rgba(31, 31, 31, 0.5);
}

.tooltip {
  font-size: 0.95rem !important;
  font-weight: 400 !important;
  max-width: 50vw !important;
  border-radius: 10px;
  margin: 15px;
  line-height: 1.4;
  z-index: 5;
  cursor: pointer;
}

.tooltip.sidebar {
  margin: 5px;
  background-color: grey;
}

.yellow-strip,
.grey-strip {
  background-color: var(--yellow-logo);
  color: var(--dark-alt);
  padding: 5px 10px;
  border-radius: 5px;
  margin: 10px 0px;
  font-size: 0.95rem;
  font-family: 'fira sans', sans-serif;
}

.grey-strip {
  background-color: var(--light-grey);
  color: var(--dark-alt);
  width: fit-content;
  margin: 5px;
}

.element-title {
  background-color: white;
  padding: 10px 20px;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  width: fit-content;
}

.element-title.green h6 {
  color: var(--strong-green);

}

.element-body {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  border-top-left-radius: 0px;
}

/* DIALOG */
.dialog-title {
  background-color: var(--yellow-logo);
  font-size: 1.1rem !important;
  font-family: 'fira sans', sans-serif !important;
  color: var(--black-logo);
  margin-bottom: 20px !important;
}

.dialog-content {
  margin-bottom: 20px !important;
}

.dialog-actions {
  display: flex;
  justify-content: space-evenly;
}

.dialog-button {
  width: 120px;
  margin-bottom: 30px;
}


/* MAIN LAYOUT */

/* HEADER */

/* left-side of header */
.logo-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
}

.logo {
  display: flex;
  flex-direction: column;
  width: 20px;
}

.logo-text {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 15px;
  color: var(--dark-alt);
  font-size: 1.3rem;
  letter-spacing: 1.9px;
}


/* right side of header (user menu) */

.menu-container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin-right: 0px;
  width: 100% !important;
}

.right-menu-icon {
  background-color: transparent !important;
  color: var(--grey-icons) !important;
  border-radius: 10px;
}

.right-menu-icon:hover {
  color: var(--strong-green) !important;
  background-color: var(--light-grey) !important;
}

/* bell badge icon */
.bell-button {
  color: white;
  display: inline-block;
  position: relative;
}

/* Make the badge float in the top right corner of the button */
.button__badge {
  background-color: #fa3e3e;
  border-radius: 10px;
  color: white;
  padding: 1px 3px;
  font-size: 12px;
  position: absolute;
  top: 2px;
  right: 5px;
}

.dropdown-menu {
  position: absolute;
  top: 50px;
  right: 20px;
  max-width: 260px;
  padding: 10px 20px;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: left;

}

.dropdown-menu p {
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-menu::before {
  content: '';
  position: absolute;
  top: -1px;
  right: 20px;
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
}

.dropdown-menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: var(--speed) ease;
}

.dropdown-menu.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: var(--speed) ease;
}

.dropdownItem {
  margin: 8px 0px;
  padding: 5px;
  font-size: 1rem;
  text-align: left;
}

.dropdownItem>a:hover {
  color: var(--strong-green);
}

.dropdownItem button {
  font-size: 1rem;
  text-align: left;
}

/* HOMEPAGE */

.homepage-container {
  display: flex;
  flex-direction: column;
  background-color: var(--light-grey);
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
  min-height: 92vh;
}

.dashboard-container {
  width: 80vw;
}

.keywordChart {
  height: 420px !important;
  width: 100% !important;
  padding: 5px 20px;
}

.homepage-card-content:hover .homepage-card-icon {
  background-color: var(--strong-green);
  color: var(--light);
}

.homepage-card-content h6 {
  font-size: 0.9rem;
  color: grey;
}

.homepage-card-content h4 {
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--dark-alt);
}

/* old homepage */

/* .applications-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.app-group-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.app-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 20px;
}

.app-group>h5 {
  font-family: 'Fira Sans';
  font-size: 1rem;
  margin: 0px 25px;
  padding: 10px;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.appgroup-1,
.appgroup-2,
.appgroup-3 {
  display: flex;
  background-color: white;
  margin: 0px 25px;
  padding: 15px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  gap: 20px;
}

.appgroup-4 {
  width: '100%';
}

.app-box {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.05rem;
  width: 100px;
  height: 85px;
  background-color: var(--yellow-logo);
  border-radius: 10px;
  padding: 20px;
}

.app-box:hover {
  background-color: var(--strong-green);
  color: var(--light);
} */

/* FOOTER */
.footer {
  background-color: var(--light-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8vh;
  border-top: 1px solid rgba(161, 153, 153, 0.106);
}

.footer>p {
  font-size: 0.85rem;
  margin: 0px 10px;
  font-family: "Fira Sans", sans-serif;
}

/* LOADING SCREEN  */
.app-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--light-grey);
  align-items: center;
  min-height: 100vh;
  width: 100%;
}

/* ALL TOOLS */

.app-home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: var(--light-grey);
  min-height: 92vh;
  padding: 20px;
}

.app-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px 0px;
  width: 80vw;
}

.app-title h5 {
  margin-top: 10px;
  font-size: 1.2rem;
}

.app-title p {
  font-size: 1rem;
  width: 60vw;
}

.bottomNavigation {
  display: flex;
  margin: 10px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  gap: 10px;
}

.bottomNavigation>button {
  margin: 0px 10px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
  width: 80vw;
}



/* TABLES */

.table-container {
  overflow-y: auto;
  width: 100%;
  border-radius: 4px;
}

table {
  border-collapse: collapse;
  background-color: white;
  overflow-x: scroll;
  overflow-y: scroll;
  width: 100%;
}

th {
  font-size: 14px;
  padding: 8px;
  border: 0.5px solid #cacacabb;
  text-align: center;
  background-color: var(--table-th-bg);
  position: sticky;
  top: 0;
}

/* non-sticky th */
.non-sticky th {
  position: static;
  z-index: auto;
}

th i {
  padding: 5px;
}

td {
  padding: 8px;
  font-size: 14px;
  text-align: center;
  border: 0.5px solid #cacacabb;
}

.tableNums {
  background-color: var(--background-grey);
}

.tableNames {
  background-color: var(--yellow-logo);
}

tr:hover {
  background-color: var(--background-grey);
}

td:hover {
  background-color: var(--background-grey);
}


/* MUI ELEMENTS */
.Mui-disabled {
  color: #a7a7a7 !important;
  background-color: rgba(226, 224, 224, 0.15) !important;
  border-radius: 2px;
  font-size: 0.7rem;
}

.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px !important;
}

.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:focus {
  background-color: #ffffffad !important;
  border: var(--strong-green);
}

.MuiInputBase-root-MuiInput-root,
.MuiFormHelperText-root,
.MuiFormHelperText-sizeMedium,
.MuiFormHelperText-contained,
.css-1wc848c-MuiFormHelperText-root,
.MuiFormHelperText-sizeMedium,
.MuiFormHelperText-contained {
  background-color: #ffffffad !important;
  width: 100% !important;
  font-size: 0.8rem !important;
  border-bottom: none !important;
  margin-left: 5px !important;
  line-height: 0.95rem !important;
}

.TextField {
  margin: 6px !important;
}

.TextField input {
  font-size: 1rem !important;
  border: none !important;
}

.TextField label {
  font-size: 1.2rem !important;
  color: var(--dark-alt) !important;
  text-overflow: clip !important;
  white-space: wrap !important;
  overflow: hidden !important;
}

.MuiFormLabel-root-MuiInputLabel-root.Mui-disabled {
  color: #a7a7a7 !important;
  background-color: transparent !important;
}

.Mui-disabled-MuiInputLabel-root {
  color: var(--dark-alt) !important;
}


/* LOGIN + SIGNUP PAGE */

.login-background,
.signup-background {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-grey);
  min-height: 100vh;
}

.login-main-container,
.signup-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  min-width: 30vw;
  box-shadow: 0 0 0.5rem rgba(31, 31, 31, 0.081);
}

.login-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.login-logo-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.login-logo-container img {
  width: 20px;
}

.login-logo-container h4 {
  color: var(--strong-green);
  letter-spacing: 0.9px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px 0px;
  padding: 20px;
  gap: 20px;
}

.login-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.login-input {
  box-shadow: inset 0 0 0 1px rgba(31, 31, 31, 0.081);
  border: none;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
}

.login-top-row-input {
  box-shadow: inset 0 0 0 1px rgba(31, 31, 31, 0.081);
  border: none;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
}

.loginBtn {
  padding: 15px 0px;
  margin: 0px;
  width: 100%;
}

.signin-help {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  width: 100%;
}

.signin-help a:hover {
  color: var(--strong-green);
}


/* PRIVACY + COOKIES */
.privacy-container,
.cookie-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: white;
  padding: 40px;
  margin: 25px;
  border-radius: 10px;
  text-align: left;
  box-shadow: 0 0 0.5rem rgba(31, 31, 31, 0.081);
  width: 80vw;
}

.privacy-text,
.cookie-text {
  font-size: 0.8rem;
  line-height: 1.6rem;
  margin: 10px 0px;
}

.privacy-text a,
.cookie-text a {
  color: var(--strong-green);
}

.privacy-text a:hover,
.cookie-text a:hover {
  color: var(--dark-green);
}

.privacy-text ul,
.cookie-text ul {
  font-size: 1rem;
  margin: 10px 15px;
}


/* ADMIN PAGE */
.admin-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  justify-content: center;
  gap: 50px;
  width: 80vw;
  margin-top: 40px;
}

.admin-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.admin-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 0px;
  width: 100%;
}

.contacts-overview-table-container {
  overflow-y: auto;
  max-height: 70vh;
  min-height: 40vh;
}

.contacts-overview-table-container table th {
  padding: 10px;
  min-width: 80px;
}

/* SUPERADMIN PAGE */

.superAdmin-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  /* width: 90%; */
  margin: 10px 0px;
}

.superAdmin-table-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
}

.superAdmin-table-container {
  overflow-x: scroll;
  overflow-y: scroll;
  max-height: 60vh;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  min-height: 40vh;
}

/* 1. QUESTIONS APP */

.questions-main-container {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  width: 60vw;
  margin-top: 30px;
}

.questions-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  max-width: 30vw;
}

.questions-1-btns {
  display: flex;
  width: 100%;
  margin-top: 15px;
  gap: 10px;
}

.questions-1-btns button {
  width: 100%;
  margin: 5px 0px;
  padding: 16px;
}

/* QUESTIONS PAGE 2 */
.questions2-main-container,
.questions3-main-container,
.questions4-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
  text-align: left;
}

.questions2-eight-answers-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;
}

.questions2-eight-answers-row {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  flex-wrap: wrap;
}

/* QUESTIONS PAGE 3 */
.questions3-main-container {
  align-items: flex-start;
  justify-content: center;
}

.questions3-section,
.questions4-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 100%;
  border-radius: 10px;
  border-top-left-radius: 0%;
  text-align: left;
}

.reminder-section {
  padding: 20px;
  border: 1px solid #cacacabb;
  border-radius: 10px;
  margin-top: 10px;
  background-color: white;
}

/* QUESTIONS PAGE 4 */
.questions4-main-container {
  align-items: flex-start;
}



/* CONTACTS APP */
.contacts-main-container {
  display: flex;
  flex-direction: column;
  width: 80vw;
  margin-top: 20px;
  border-radius: 10px;
}

.contacts1-mainQ-container {
  display: flex;
  flex-direction: column;
  width: 80vw;
  gap: 40px;
}

.contacts1-top-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 40px;
  gap: 40px;
  max-height: 60vh;
}

.contacts1-right-container {
  width: 100%;
}

.contacts1-left-container {
  width: 75%;
}

.contacts1-left-content-container,
.contacts1-right-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}

.contacts1-left-content-container {
  gap: 20px;
}

.contacts1-left-content-container div {
  width: 100%;
}

.contacts1-bottom-container {
  display: flex;
  flex-direction: column;
}


.contacts1-bottom-table-container {
  min-height: 40vh;
  padding: 20px;
}

.list-details {
  display: flex;
  width: 100%;
  align-items: baseline;
}

.list-details>input,
.list-details>select,
.contacts1-list-container>select,
.contacts1-mainQ-container>select {
  margin: 5px;
  padding: 10px 20px;
  text-align: left;
  font-size: 15px;
  border: none;
}

.contacts-table-container {
  overflow-y: auto;
  max-height: 60vh;
  width: 100%;
}

.contacts-table-container table th {
  padding: 5px 8px;
  font-size: 0.85em;
  word-wrap: break-word;
  white-space: normal;
  vertical-align: center;
}

.contacts-table-container table td {
  font-size: 0.85em;
}


/* name, keywords, lists */
.contacts-table-container table th:nth-child(2),
.contacts-table-container table th:nth-child(10),
.contacts-table-container table th:nth-child(12) {
  width: 20%;
}

/* questions */
.contacts-table-container table th:nth-child(13) {
  width: 30%;
}

/* contacts-2 */
.contacts2-main-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: flex-start;
  width: 80vw;
  border-radius: 10px;
}

.contacts-modal-container {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
}


.mainq2 {
  display: flex;
  text-align: left;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  border-top-left-radius: 0px;
}

.contacts-input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  border-bottom: none;
  text-align: center;
  font-size: 14px;
  min-width: 180px;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--background-grey);
  box-shadow: inset 0 0 0.1rem rgba(31, 31, 31, 0.5);
  width: 100%;
}

/* contacts-3 */

.contacts3-main-container {
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  border-radius: 10px;
  padding: 20px;
}

.contacts3-section {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
}

.question-group {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  margin-bottom: 35px;
  border-radius: 5px;
  background-color: white;
  padding: 15px;
  border-top-left-radius: 0%;
}

.question-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
}

.question-container>select {
  min-width: 150px;
  margin-left: 20px;
}

.question-container>p {
  margin-right: 25px;
}

.question-container span {
  font-weight: 500;
}

.contacts3-select {
  min-width: 180px;
  padding: 5px !important;
}

.contacts3-select .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  padding: 0px;
  border-radius: 4px;
  font-size: 16px;
}

/* ANALYSIS APP */
/* analysis page 1 */
.analysis-main-container {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  gap: 30px;
}

/* anaylsis page 2 */
.analysis2-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  gap: 20px;
}

/* classes for cells colors */
.analysis-table {
  background-color: white;
  border-collapse: collapse;
  width: fit-content;
  padding: 20px;
  border-radius: 10px;
}

.analysis-select {
  width: 35px;
  padding: 0px;
  background-color: transparent;
  border: 1px solid #504a4a64;
}

.analysis-section {
  flex-direction: row;
  justify-content: space-evenly;
}

.analysis-section-title {
  font-size: 1rem;
  font-weight: 500;
  margin: 15px;
}

.analysis-section>select {
  margin: 0px 10px;
  padding: 5px;
}

.analysis-td {
  text-align: center;
}

.selected-bg-color {
  background-color: var(--strong-green);
  color: white;
}

/* analysis page 3 */
.anaylsis3-top-container {
  display: flex;
  margin: 30px 0px;
  text-align: center;
  align-content: center;
  justify-content: center;
  width: 80vw;
  gap: 20px;
}

.analysis3-main-container {
  display: flex;
  flex-direction: column;
  width: 80vw;
}


.analysis3-section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 10px;
  /* flex-wrap: wrap; */
  width: 50%;
}


.analysis3-section p {
  font-size: 15px;
}

.analysis3-section select {
  width: 180px;
  padding: 5px;
  margin-top: 10px;
  font-family: "Fira Sans", sans-serif;
  text-align: center;
}

.network-chart,
.analysis-contact-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.analysis-contacts-table td {
  border: 1px solid #6b5d5d21 !important;
}

.Clarity,
.Priority {
  background-color: var(--yellow-logo) !important;
}

/* PULLING APP */

.pulling-top-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 80vw;
}

.pulling-main-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 20px 0px;
  width: 80vw;
}

/* pulling contacts table */
.pulling-table-container {
  padding: 10px;
  height: 30vh;
}

.pulling-table-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.pulling-selection-group {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  text-align: left;
  align-content: center;
  background-color: white;
}

/* left and right sides of the screen */
.pulling-before-container,
.pulling-after-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  border-radius: 10px;
}

.pulling-before-container {
  max-width: 30vw;
}

/* parts of left screen */
.contactSelection,
.pulling-meeting-info,
.pulling-pitch {
  display: flex;
  flex-direction: column;
  margin: 10px;
  min-width: 20vw;
  border-radius: 5px;
  /* gap: 10px; */
}

.pulling-pitch {
  gap: 20px;
}

.right-side-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  gap: 20px;
}

.textarea-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.TextField-pulling-area {
  margin: 15px 5px !important;
}

/* CONCLUDING APP */

.concluding-top-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  width: 80vw;
}

.concluding-main-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 40px 0px;
  width: 80vw;
  gap: 30px;
}

.concluding-left,
.concluding-right {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  border-radius: 10px;
}

.concluding-left {
  max-width: 25vw;
}

/* left side button */
.concluding-top-btns {
  display: flex;
  padding-top: 10px;
  flex-direction: column;
  width: 100%;
}

.concluding-group {
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin: 5px;
  border: 1px dashed rgba(186, 186, 186, 0.216);
  border-radius: 5px;
  width: 100%;
  text-align: left;
}

.answersArea {
  margin-top: 15px;
}

.concluding-textarea-group {
  display: flex;
  align-items: center;
  width: 100%;
}

.concluding-top-row,
.concluding-bottom-row,
.group-row,
.conclusion {
  display: flex;
  width: 100%;
  margin: 5px 0px;
}

.row-titles {
  margin: 5px;
}


/* MAP APP */
.map-btns-top-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 30px;
  width: 80vw;
}

.map-container {
  display: flex;
  flex-direction: column;
  width: 80vw;
}

.changes-container,
.decisions-container,
.map-bottom-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.map-bottom-container {
  min-height: 500px;
  align-items: center;
  justify-content: center;
}

.mapsTd {
  border-bottom: 0.05rem solid #32323286;
  background-color: white;
  padding: 2px 5px;
}

.mapsTh {
  padding: 7px 5px;
  font-weight: 500;
  font-size: 15px;
  font-family: 'fira-sans', sans-serif;
  border-bottom: 0.05rem solid #32323286;
  background-color: var(--yellow-logo);
}


.mapsSelect {
  min-width: 5px;
  padding: 0px;
  margin: 0px;
  background-color: #ddd6d623 !important;
  border: 1px solid #6362627a !important;
}

.mapsSelect>option {
  padding: 0px;
  margin: 0px;
}

.mapsSelect:hover {
  background-color: var(--strong-green) !important;
  color: #0000007a !important;
  border: 1px solid #0000007a !important;
}

.optionSelected {
  background-color: var(--strong-green) !important;
  color: white;
}


/* RESULTS APP */

.results-top-container,
.results-bottom-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 80vw;

}

.results-top-container {
  gap: 40px;
  margin: 35px 0px;
}

.results-top-container-left,
.results-top-container-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;

}

.analysis-btns {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 35px;
}

.btn-group {
  display: flex;
  margin: 5px 0px;
}

.btn-group>button,
.btn-group>select {
  margin: 0px 5px;
  min-width: 80px;
  width: fit-content;
}

.results-bottom-container {
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}

.results-table {
  border-collapse: separate;
  border-spacing: 10px 0px !important;
}

.results-table-bar {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 20px;
}

.results-table th {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border: none;
  padding: 5px;
  font-size: 0.95rem;
}

.results-table>thead>tr:hover {
  background-color: transparent;
}

/* removes background from top row */
.transparent-bg {
  background-color: transparent !important;
}

/* CV APP */

.cv-top-container,
.cv-middle-container,
.cv-bottom-container {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  margin: 0px 20px;
  border-radius: 5px;
  align-items: flex-start;
  width: 80vw;
}

.cv-group {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 35px;
}

.cv-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  gap: 10px;
}

.cv-box-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.cv-box>p {
  font-weight: 500;
}

.cv-graph-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;

}

.cv-graph-container.rightleg {
  margin-top: 50px;
  max-height: 400px;
}

/* REPORTS APP */
.reports-main-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80vw;
}

.reports-container {
  text-align: left;
  width: 100%;
  margin: 20px 0px;
  border-radius: 10px;
}


/* reports page 2 */
.reports-row-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
}

.reports-row-fields {
  margin-bottom: 15px;
  display: flex;
}

.bullet-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 5px 0px;
}

/* for bullet table, reports 1 */
.bullet-entry {
  margin-bottom: 5px;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

/* reports page 3 */
.MuiPaper-root-MuiAccordion-root:before {
  height: 0px !important;

}

.search-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* PROFILE PAGE */

.profile-form-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 25vw;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  gap: 20px;
  margin-bottom: 30px;
}

.profile-form-title p {
  margin: 10px 0px;
}

.profile-form {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 100%;
  gap: 20px;
}

.profile-form>input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #cacacabb;
  width: 100%;
}




/* MEDIA QUERIES */

/* login page */
@media screen and (max-width: 1200px) {
  .login-main-container {
    min-width: 40vw;
  }
}

@media screen and (max-width: 800px) {
  .login-main-container {
    min-width: 50vw;
  }
}

@media screen and (max-width: 700px) {
  .login-main-container {
    min-width: 60vw;
  }

  .signin-help {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

/* admin page */
@media screen and (max-width: 1200px) {
  .admin-main-container {
    width: 90vw;
  }
}

/* cv app */
@media screen and (max-width: 1000px) {
  .cv-group-container {
    flex-direction: column;
  }
}

/* all pages */
@media screen and (max-width: 800px) {
  .app-title {
    flex-direction: column;
    align-items: center;
  }

  .app-title h5 {
    font-size: 1.5rem;
  }

  .app-title h6,
  .app-title p {
    font-size: 1rem;
  }

}

/* homepage: grid layout */
@media screen and (max-width: 800px) {
  /* .app-home-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    gap: 20px;
  } */
}